import * as React from 'react';

import Layout from '../components/simple-layout.js';

// TODO: add a funny privacy image / twitter card
const PrivacyPolicy = () => (
  <Layout title="Privacy Policy" description="Privacy Policy Page">
    {/* TODO: Extract this layout as a single-page layout */}
    <div className="relative px-4 pt-4 pb-8 mt-4 bg-gray-50 sm:px-6 lg:pt-6 lg:pb-6 lg:px-8">
      <div className="relative mx-auto max-w-7xl">
        <div className="relative py-16 overflow-hidden bg-white">
          <main>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <div className="bg-indigo-700">
                  <div className="max-w-2xl px-4 py-16 mx-auto text-center sm:py-20 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                      <span className="block">Privacy Policy</span>
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-indigo-200">
                      I will not spam you nor sell your data to third
                      parties.
                      <br />
                      This site uses cookies to personalize content
                      and analyze traffic. If you'd like to opt-out,
                      please ignore this banner!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
